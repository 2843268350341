<template>
  <div style="padding-bottom: 150px;">
    <b-tabs justified pills>
      <b-tab title="สรุปข้อมูลประจำเดือน" lazy>
        <div class="d-flex flex-column flex-sm-row justify-content-end align-items-end mb-1">
          <p class="m-0 mb-50">เลือกเดือน-ปี</p>
          <b-card body-class="p-0" class="m-0 ml-sm-1 w-100" style="max-width: 150px;">
            <v-select
              v-model="dateSelected"
              :options="dateSelectOptions"
              :clearable="false"
              :disabled="isLoading"
              :get-option-label="option => $date(option).format('MM-YYYY')"
            />
          </b-card>
        </div>

        <OverviewsImage :date="dateSelected" />

        <h4 class="my-1">สรุปข้อมูลผู้ใช้งาน</h4>
        <OverviewsUserDetails :date="dateSelected" />

        <h4 class="my-1">สรุปข้อมูลธุรกรรม</h4>
        <OverviewsTransaction :date="dateSelected" />
      </b-tab>

      <b-tab title="สรุปข้อมูลประจำปี" lazy>
        <div class="d-flex flex-column flex-sm-row justify-content-end align-items-end mb-1">
          <p class="m-0 mb-50">เลือกปี</p>
          <b-card body-class="p-0" class="m-0 ml-sm-1 w-100" style="max-width: 150px;">
            <v-select
              v-model="datePerYearSelected"
              :options="datePerYearOptions"
              :clearable="false"
              :disabled="isLoading"
            />
          </b-card>
        </div>
        <OverviewsImage :date="datePerYearSelected" date-type="year" />

        <h4 class="my-1">สรุปข้อมูลผู้ใช้งาน</h4>
        <OverviewsUserDetails :date="datePerYearSelected" date-type="year" />

        <h4 class="my-1">สรุปข้อมูลธุรกรรม</h4>
        <OverviewsTransaction :date="datePerYearSelected" date-type="year" />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'
import OverviewsTransaction from './components/OverviewsTransaction.vue'
import OverviewsUserDetails from './components/OverviewsUserDetails.vue'
import OverviewsImage from './components/OverviewsImage.vue'

export default {
  components: {
    vSelect,
    OverviewsUserDetails,
    OverviewsTransaction,
    OverviewsImage,
  },
  data() {
    return {
      dateSelected: null,
      dateSelectOptions: [],
      isLoading: false,
      datePerYearSelected: null,
      datePerYearOptions: [],
    }
  },
  computed: {
    ...mapGetters('AuthStore', ['_isAdmin', '_isMarketing', '_isPartner', '_userInfo']),
  },
  created() {
    this.fetchAllDate()
  },
  methods: {
    async fetchAllDate() {
      this.isLoading = true
      const resp = await this.api.getV2('api/dashboard/fetch-date').catch(() => null)
      this.isLoading = false
      if (resp && resp?.code === 200 && Array.isArray(resp.data) && resp.data.length > 0) {
        const { data } = resp
        const dates = [...data]
        this.dateSelectOptions = dates
        // eslint-disable-next-line prefer-destructuring
        this.dateSelected = dates[0]

        const years = dates.map(date => date?.split('-')[0])

        const uniqueYears = [...new Set(years)]

        this.datePerYearOptions = uniqueYears
        // eslint-disable-next-line prefer-destructuring
        this.datePerYearSelected = uniqueYears[0]
      }
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/chart-apex.scss';
</style>
