<template>
  <div>
    <b-row cols="1" cols-sm="2" cols-md="3">
      <b-col v-for="item in listCard" :key="item.label">
        <b-overlay :show="cardLoading" spinner-small>
          <b-card>
            <div class="d-flex align-items-center">
              <b-avatar size="30" :variant="item.color" class="mr-50">
                <feather-icon size="16" :icon="item.icon" />
              </b-avatar>
              <span>
                {{ item.label }}
              </span>
            </div>

            <h3 class="text-center">{{ item.title }}</h3>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
    <b-card no-body>
      <b-card-header>
        <b-card-title>
          ยอดเติมเงิน&ยอดขาย
        </b-card-title>
      </b-card-header>
      <b-overlay :show="chartTopUpAndRevenueLoading" spinner-small>
        <vue-apex-charts height="350" :options="chartTopUpAndRevenueOptions" :series="chartTopUpAndRevenueSeries" />
      </b-overlay>
    </b-card>

    <b-card no-body>
      <b-card-header>
        <b-card-title>
          ยอดขายรูปครีเอเตอร์&แอดมิน
        </b-card-title>
      </b-card-header>
      <b-overlay :show="chartCreatorAndAdminRevenueLoading" spinner-small>
        <vue-apex-charts
          :height="chartCreatorAndAdminRevenueHeight"
          :options="chartCreatorAndAdminRevenueOp"
          :series="chartCreatorAndAdminRevenueSe"
        />
      </b-overlay>
    </b-card>

    <b-card no-body>
      <b-card-header>
        <b-card-title>
          รายได้ผู้ใช้ Affiliate
        </b-card-title>
      </b-card-header>
      <b-overlay :show="chartAfRevenueLoading" spinner-small>
        <vue-apex-charts :height="chartAfRevenueHeight" :options="chartAfRevenueOp" :series="chartAfRevenueSe" />
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
  },
  props: {
    date: {
      type: String,
      default: null,
    },
    dateType: {
      type: String,
      default: 'mountYear',
    },
  },
  data() {
    return {
      cardLoading: true,
      allCardData: {},

      chartTopUpAndRevenue: {},
      chartTopUpAndRevenueLoading: true,

      chartCreatorAndAdminRevenue: {},
      chartCreatorAndAdminRevenueLoading: true,

      chartAfRevenue: {},
      chartAfRevenueLoading: true,
    }
  },
  computed: {
    listCard() {
      return [
        {
          icon: 'TrendingUpIcon',
          color: 'warning',
          title: this.gFormatNumberToCurrency(this?.allCardData?.topup ?? 0),
          label: 'ยอดเติมเงิน',
        },
        // {
        //   icon: 'CheckIcon',
        //   color: 'success',
        //   title: this.gFormatNumberToCurrency(this?.allCardData?.topup ?? 0),
        //   label: 'ยอดเติมสำเร็จ',
        // },
        // {
        //   icon: 'XIcon',
        //   color: 'danger',
        //   title: this.gFormatNumberToCurrency(this?.allCardData?.topup ?? 0),
        //   label: 'ยอดเติมไม่สำเร็จ',
        // },
        {
          icon: 'TrendingUpIcon',
          color: 'success',
          title: this.gFormatNumberToCurrency(this?.allCardData?.sale ?? 0),
          label: 'ยอดขาย (ก่อนหัก)',
        },
        {
          icon: 'TrendingUpIcon',
          color: 'primary',
          title: this.gFormatNumberToCurrency(this?.allCardData?.sale_after_percent ?? 0),
          label: 'ยอดขายสุทธิ',
        },
        {
          icon: 'DollarSignIcon',
          color: 'warning',
          title: this.gFormatNumberToCurrency(this?.allCardData?.affiliate_revenue ?? 0),
          label: 'รายได้ผู้ใช้ Affiliate',
        },
        {
          icon: 'TrendingDownIcon',
          color: 'danger',
          title: this.gFormatNumberToCurrency(this?.allCardData?.creator_withdraw ?? 0),
          label: 'ยอดครีเอเตอร์ถอนเงิน',
        },
        {
          icon: 'TrendingDownIcon',
          color: 'danger',
          title: this.gFormatNumberToCurrency(this?.allCardData?.refund ?? 0),
          label: 'ยอดรีฟัน',
        },
        {
          icon: 'DollarSignIcon',
          color: 'success',
          title: this.gFormatNumberToCurrency(this?.allCardData?.admin_add_credit ?? 0),
          label: 'แอดมินเพิ่มเครดิตผู้ใช้งานทั่วไป',
        },
        {
          icon: 'DollarSignIcon',
          color: 'danger',
          title: this.gFormatNumberToCurrency(this?.allCardData?.admin_reduce_credit ?? 0),
          label: 'แอดมินลดเครดิตผู้ใช้งานทั่วไป',
        },
      ]
    },
    isDateTypeYear() {
      return this.dateType === 'year'
    },
    chartTopUpAndRevenueOptions() {
      const self = this

      return {
        chart: {
          type: 'line',
          stacked: false,
        },
        colors: ['#fec300', '#15c755', '#7878f2'],

        stroke: {
          width: [0, 0],
        },
        legend: {
          show: true,
        },
        xaxis: {
          categories: this.chartTopUpAndRevenue?.days ?? [],
          labels: {
            formatter(value) {
              return self?.$date(value).format(self?.isDateTypeYear ? 'MM' : 'DD')
            },
          },
        },
        yaxis: {
          labels: {
            formatter(value) {
              return self?.gFormatNumberToCurrency(value)
            },
          },
        },
        tooltip: {
          x: {
            formatter(value, { dataPointIndex }) {
              return self
                ?.$date(self?.chartTopUpAndRevenue?.days?.[dataPointIndex])
                .format(self?.isDateTypeYear ? 'MM/YYYY' : 'DD/MM/YYYY')
            },
          },
        },
      }
    },
    chartTopUpAndRevenueSeries() {
      return [
        {
          name: 'ยอดเติมเงิน',
          data: this.chartTopUpAndRevenue?.topup ?? [],
          type: 'column',
        },
        {
          name: 'ยอดขาย (ก่อนหัก)',
          data: this.chartTopUpAndRevenue?.sale ?? [],
          type: 'column',
        },
        {
          name: 'ยอดขายสุทธิ',
          data: this.chartTopUpAndRevenue?.sale_after_percent ?? [],
          type: 'column',
        },
      ]
    },

    chartCreatorAndAdminRevenueOp() {
      const self = this

      return {
        chart: {
          type: 'bar',
        },
        colors: ['#15c755', '#ea5455', '#7878f2'],
        xaxis: {
          categories: this.chartCreatorAndAdminRevenue?.shop_name ?? [],
        },
        yaxis: {
          labels: {
            show: true,
          },
        },
        tooltip: {
          y: {
            formatter(value) {
              return self?.gFormatNumberToCurrency(value)
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter(val, opt) {
            return `${opt.w.globals.labels[opt.dataPointIndex]} : ${self?.gFormatNumberToCurrency(val)}`
          },
          textAnchor: 'start',
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: '70%',
            dataLabels: {
              position: 'bottom',
            },
          },
        },
        responsive: [
          {
            breakpoint: 768,
            options: {
              yaxis: {
                labels: {
                  show: false,
                },
              },
            },
          },
        ],
      }
    },
    chartCreatorAndAdminRevenueSe() {
      return [
        {
          name: 'ยอดขาย (ก่อนหัก)',
          data: this.chartCreatorAndAdminRevenue?.sale_total ?? [],
        },
        {
          name: 'ยอดหักค่าส่วนแบ่ง % Affiliate',
          data: this.chartCreatorAndAdminRevenue?.affiliate_revenue ?? [],
        },
        {
          name: 'ยอดขายสุทธิ',
          data: this.chartCreatorAndAdminRevenue?.sale_after_percent ?? [],
        },
      ]
    },
    chartCreatorAndAdminRevenueHeight() {
      if (this.chartCreatorAndAdminRevenue?.shop_name?.length === 0) return 200

      const baseHeight = 350
      const newHeight = this.chartCreatorAndAdminRevenue?.shop_name?.length * 50 + 600

      if (newHeight > baseHeight) return newHeight

      return baseHeight
    },

    chartAfRevenueOp() {
      const self = this
      return {
        chart: {
          type: 'bar',
        },
        colors: ['#15c755'],
        xaxis: {
          categories: this.chartAfRevenue?.affiliate ?? [],
        },
        yaxis: {
          labels: {
            show: true,
          },
        },
        tooltip: {
          y: {
            formatter(value) {
              return self?.gFormatNumberToCurrency(value)
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter(val, opt) {
            return `${opt.w.globals.labels[opt.dataPointIndex]} : ${self?.gFormatNumberToCurrency(val)}`
          },
          textAnchor: 'start',
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: '70%',
            dataLabels: {
              position: 'bottom',
            },
          },
        },
        responsive: [
          {
            breakpoint: 768,
            options: {
              yaxis: {
                labels: {
                  show: false,
                },
              },
            },
          },
        ],
      }
    },
    chartAfRevenueSe() {
      return [{ name: 'รายได้สุทธิ', data: this.chartAfRevenue?.affiliate_revenue ?? [] }]
    },
    chartAfRevenueHeight() {
      const baseHeight = 200
      const newHeight = this.chartAfRevenue?.affiliate_revenue * 60

      if (newHeight > baseHeight) return newHeight

      return baseHeight
    },
  },
  watch: {
    date(newValue) {
      this.fetchCardData(newValue)
      this.fetchChartTopUpAndRevenue(newValue)
      this.fetchChartCreatorAndAdminRevenue(newValue)
      this.fetchChartAfRevenue(newValue)
    },
  },
  mounted() {
    this.fetchCardData(this.date)
    this.fetchChartTopUpAndRevenue(this.date)
    this.fetchChartCreatorAndAdminRevenue(this.date)
    this.fetchChartAfRevenue(this.date)
  },
  methods: {
    async fetchCardData(date) {
      if (!date) return

      this.cardLoading = true

      const resp = await this.api
        .getV2(`api/admin/new-dashboard/DashboardReportTransactionIconService/${date}`)
        .catch(() => null)

      if (resp?.code === 200) {
        this.allCardData = { ...resp.data }
      }

      this.cardLoading = false
    },
    async fetchChartTopUpAndRevenue(date) {
      if (!date) return

      this.chartTopUpAndRevenueLoading = true

      const resp = await this.api
        .getV2(`api/admin/new-dashboard/DashboardReportTransactionDataService/${date}`)
        .catch(() => null)

      if (resp?.code === 200) {
        this.chartTopUpAndRevenue = { ...resp.data }
      }

      this.chartTopUpAndRevenueLoading = false
    },

    async fetchChartCreatorAndAdminRevenue(date) {
      if (!date) return

      this.chartCreatorAndAdminRevenueLoading = true

      const resp = await this.api
        .getV2(`api/admin/new-dashboard/DashboardChartReportCreatorAndAffiliateService/${date}`)
        .catch(() => null)

      if (resp?.code === 200) {
        this.chartCreatorAndAdminRevenue = { ...resp.data }
      }

      this.chartCreatorAndAdminRevenueLoading = false
    },

    async fetchChartAfRevenue(date) {
      if (!date) return

      this.chartAfRevenueLoading = true

      const resp = await this.api
        .getV2(`api/admin/new-dashboard/DashboardChartReportAffiliateRevenueService/${date}`)
        .catch(() => null)

      if (resp?.code === 200) {
        this.chartAfRevenue = { ...resp.data }
      }

      this.chartAfRevenueLoading = false
    },
  },
}
</script>
