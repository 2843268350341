<template>
  <div>
    <b-row cols="1" cols-sm="2">
      <b-col v-for="item in listCard" :key="item.label">
        <b-overlay :show="loading" spinner-small>
          <b-card class="mb-1">
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar size="48" :variant="item.color">
                  <feather-icon size="24" :icon="item.icon" />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ item.title }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  {{ item.label }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    date: {
      type: String,
      default: null,
    },
    dateType: {
      type: String,
      default: 'mountYear',
    },
  },
  data() {
    return {
      loading: true,
      cardAllData: {},
    }
  },
  computed: {
    listCard() {
      return [
        {
          icon: 'ImageIcon',
          color: 'primary',
          title: this.gFormatNumberToCurrency(this.cardAllData?.countImage ?? 0),
          label: 'รูปวอลเปเปอร์ทั้งหมด',
        },
        {
          icon: 'ImageIcon',
          color: 'warning',
          title: this.gFormatNumberToCurrency(this.cardAllData?.countZodiac ?? 0),
          label: 'รูปราศีทั้งหมด',
        },
      ]
    },
  },
  watch: {
    date(newValue) {
      this.fetchData(newValue)
    },
  },
  mounted() {
    this.fetchData(this.date)
  },
  methods: {
    async fetchData(date) {
      if (!date) return
      this.loading = true
      const resp = await this.api
        .getV2(`api/admin/new-dashboard/DashboardReportTopicDataService/${date}`)
        .catch(() => null)

      if (resp?.code === 200) {
        this.cardAllData = { ...resp.data }
      }
      this.loading = false
    },
  },
}
</script>
