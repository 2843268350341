<template>
  <div>
    <b-row cols="1" cols-sm="2">
      <b-col v-for="item in listCard" :key="item.label">
        <b-overlay :show="loading" spinner-small>
          <b-card class="mb-1">
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar size="48" :variant="item.color">
                  <feather-icon size="24" :icon="item.icon" />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ item.title }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  {{ item.label }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>

    <b-card no-body>
      <b-card-header>
        <b-card-title>
          รายงานผู้ใช้ทั่วไปสมัครเข้ามาใช้งาน
        </b-card-title>
      </b-card-header>
      <b-overlay :show="chartLoading" spinner-small>
        <b-card-body>
          <vue-apex-charts height="350" :options="vueChartOptions" :series="vueChartSeries" />
        </b-card-body>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
  },
  props: {
    date: {
      type: String,
      default: null,
    },
    dateType: {
      type: String,
      default: 'mountYear',
    },
  },
  data() {
    return {
      loading: true,
      chartLoading: true,
      cardAllData: {},
      allChartData: {
        labels: [],
        values: [],
      },
    }
  },
  computed: {
    listCard() {
      return [
        {
          icon: 'UsersIcon',
          color: 'primary',
          title: this.gFormatNumberToCurrency(this.cardAllData?.countUser ?? 0),
          label: 'ผู้ใช้งานทั่วไป',
        },
        {
          icon: 'UsersIcon',
          color: 'success',
          title: this.gFormatNumberToCurrency(this.cardAllData?.countCreator ?? 0),
          label: 'ครีเอเตอร์',
        },
        {
          icon: 'UsersIcon',
          color: 'warning',
          title: this.gFormatNumberToCurrency(this.cardAllData?.countAffiliate ?? 0),
          label: 'ผู้ใช้งาน Affiliate',
        },
        {
          icon: 'UsersIcon',
          color: 'secondary',
          title: this.gFormatNumberToCurrency(this.cardAllData?.countAffiliateDummy ?? 0),
          label: 'ผู้ใช้งาน Affiliate (Dummy)',
        },
      ]
    },
    vueChartSeries() {
      return [
        {
          name: this.isDateTypeYear ? 'ผู้สมัครเข้ามาใช้งาน' : 'ผู้ใช้งานใหม่ 24 ชม.',
          data: this.allChartData.values ?? [],
          type: 'column',
        },
      ]
    },
    isDateTypeYear() {
      return this.dateType === 'year'
    },
    vueChartOptions() {
      const self = this

      return {
        chart: {
          type: 'line',
          stacked: false,
        },
        colors: ['#5d5fef'],

        stroke: {
          width: [2],
        },
        legend: {
          show: true,
        },
        xaxis: {
          categories: this.allChartData.labels ?? [],
          labels: {
            formatter(value) {
              return self?.$date(value).format(self?.isDateTypeYear ? 'MM' : 'DD')
            },
          },
        },
        yaxis: {
          labels: {
            formatter(value) {
              return self?.gFormatNumberToCurrency(value)
            },
          },
        },
        tooltip: {
          x: {
            formatter(value, { dataPointIndex }) {
              return self
                ?.$date(self.allChartData?.labels?.[dataPointIndex])
                .format(self?.isDateTypeYear ? 'MM/YYYY' : 'DD/MM/YYYY')
            },
          },
        },
      }
    },
  },
  watch: {
    date(newValue) {
      this.fetchData(newValue)
      this.fetchChartsData(newValue)
    },
  },
  mounted() {
    this.fetchData(this.date)
    this.fetchChartsData(this.date)
  },
  methods: {
    async fetchData(date) {
      if (!date) return
      this.loading = true
      const resp = await this.api
        .getV2(`api/admin/new-dashboard/DashboardReportUserDataService/${date}`)
        .catch(() => null)

      if (resp?.code === 200) {
        this.cardAllData = { ...resp.data }
      }
      this.loading = false
    },
    async fetchChartsData(date) {
      if (!date) return
      this.chartLoading = true
      const resp = await this.api
        .getV2(`api/admin/new-dashboard/DashboardChartReportUserService/${date}`)
        .catch(() => null)
      this.chartLoading = false
      if (resp?.code === 200) {
        this.allChartData = { ...resp.data }
      }
    },
  },
}
</script>
